import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button3d } from 'components/button/Button';
import CircularLoader from 'components/loader/CircularLoader';
import { BundleFeature } from 'components/subscription/BundleFeature';
import { getGlobalSubscriptionPriceApi } from 'store/slices/public/publicApi';
import { useTranslation } from 'react-i18next';

interface Prop {
	activeBundle: any;
	selectedValidity: any;
	user: any;
	subscriptionData: any;
	setSelectedValidity: any;
}

export interface ModalProp {
	status: boolean;
	type: string;
	modalObj: any;
}

export function PremiumSubscription({
	activeBundle,
	selectedValidity,
	user,
	subscriptionData,
	setSelectedValidity,
}: Prop) {
	const navigate = useNavigate();
	const { t } = useTranslation('common');
	const [currency, setCurrency] = useState('');

	const { isLoading } = useQuery(
		['get-all-validity', subscriptionData], // Including 'id' as a dependency
		async () => {
			const activeBundle = subscriptionData?.data?.itemsList?.find(
				(bundle: any) => bundle.title === 'premium plan'
			);

			return await getGlobalSubscriptionPriceApi({
				subscriptionPlanId: activeBundle?._id,
				countryCode: user?.countryCode,
			});
		},
		{
			enabled: !!subscriptionData,
			onSuccess: (res: any) => {
				const validity =
					res?.data?.subscriptionPlanValidity?.length > 0 &&
					(res?.data?.subscriptionPlanValidity?.find(
						(item: any) => parseFloat(item.month) === 6
					) ||
						res?.data?.subscriptionPlanValidity?.[0]);
				setSelectedValidity(validity);
				setCurrency(res?.data?.currencyId?.symbol);
			},
			onError: (error: any) => {
				console.error('Error fetching subscription prices:', error);
			},
			retry: 0,
		}
	);

	const discountAmount = parseFloat(selectedValidity?.amount) ?? 0;

	const discountRate =
		(parseFloat(selectedValidity?.subscriptionDiscount) ?? 0) / 100;

	const finalAmount = discountAmount - discountAmount * discountRate;

	const handleComplete = () => {
		localStorage.setItem('isFirstTimeUser', 'false');
		navigate('/subscriptions?type=premium');
	};

	return (
		<div>
			{isLoading ? (
				<CircularLoader />
			) : (
				<>
					{activeBundle && (
						<div className="relative w-full bg-[#FFFFFF0A] border border-[#FFFFFF14] rounded-[12px] mb-10">
							{parseFloat(selectedValidity?.subscriptionDiscount) > 0 && (
								<div className="bg-[#E5FFC3] py-1 px-4 italic text-[1rem] text-center font-semibold w-full h-[34px] rounded-t-[12px]">
									Save {selectedValidity?.subscriptionDiscount}%
								</div>
							)}

							{selectedValidity && (
								<div className="py-4 px-3">
									{parseFloat(selectedValidity?.subscriptionDiscount) > 0 && (
										<div className="w-fit relative">
											<p className="text-[20px] text-[#FFFFFFB2] mb-3 w-fit">
												{currency} {selectedValidity?.amount}
											</p>
											<div className="absolute border border-[#FFFFFFB2] bottom-4 w-full" />
										</div>
									)}
									<p className="text-green">
										{parseFloat(selectedValidity?.subscriptionDiscount) > 0 &&
										currency ? (
											<>
												{finalAmount && (
													<span className="text-[2rem]">
														{currency}
														{finalAmount}/
													</span>
												)}
											</>
										) : (
											<span className="text-[2rem]">
												{currency} {parseFloat(selectedValidity?.amount)}/
											</span>
										)}
										<span className="text-[14px]">
											{selectedValidity?.month} months
										</span>
									</p>
								</div>
							)}

							{activeBundle?.ice && (
								<BundleFeature
									feature={`${activeBundle?.ice.gameAssetQuantity} Ice For ${
										activeBundle.ice?.expirationHour > 24
											? activeBundle.ice?.expirationHour / 24 + ' days'
											: activeBundle.ice?.expirationHour + ' hrs'
									}`}
								/>
							)}
							{activeBundle?.gameShield && (
								<BundleFeature
									feature={`${
										activeBundle?.gameShield.gameAssetQuantity
									} GameShield For ${
										activeBundle.gameShield?.expirationHour > 24
											? activeBundle.gameShield?.expirationHour / 24 + ' days'
											: activeBundle.gameShield?.expirationHour + ' hrs'
									}`}
								/>
							)}
							{activeBundle?.background && (
								<BundleFeature
									feature={`${
										activeBundle?.background.gameAssetQuantity
									} Background For ${
										activeBundle.background?.expirationHour > 24
											? activeBundle.background?.expirationHour / 24 + ' days'
											: activeBundle.background?.expirationHour + ' hrs'
									}`}
								/>
							)}
							{activeBundle?.shaggy && (
								<BundleFeature
									feature={`${
										activeBundle?.shaggy.gameCharacterQuantity
									} Shaggi For ${
										activeBundle.shaggy?.expirationHour > 24
											? activeBundle.shaggy?.expirationHour / 24 + ' days'
											: activeBundle.shaggy?.expirationHour + ' hrs'
									}`}
								/>
							)}
							{activeBundle?.odumeje && (
								<BundleFeature
									feature={`${
										activeBundle?.odumeje.gameCharacterQuantity
									} Odumeje For ${
										activeBundle.odumeje?.expirationHour > 24
											? activeBundle.odumeje?.expirationHour / 24 + ' days'
											: activeBundle.odumeje?.expirationHour + ' hrs'
									}`}
								/>
							)}

							<p className="text-primary py-5 px-3">
								{t('onboard.byContinuing')}{' '}
								<span className="text-green">{t('account.privacyPolicy')}</span>{' '}
								{t('util.and')}{' '}
								<span className="text-green">
									{t('account.termsAndCondition')}
								</span>
							</p>
						</div>
					)}

					{activeBundle && (
						<Button3d
							text={'Subscribe Now'}
							onClick={() => handleComplete()}
							disabled={false}
							className={`font-semibold w-full flex items-center justify-center h-[56px] mb-5`}
						/>
					)}
				</>
			)}
		</div>
	);
}
