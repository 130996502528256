import moment from 'moment';

export const usdtDenominations = [5, 10, 20, 50, 100, 200, 300, 500, 700, 1000];
export const denominations = [
	10500, 17500, 26500, 35000, 52500, 70000, 105000, 175000,
];
export const tonDenominations = [0.1, 0.2, 0.5, 1, 2, 5, 10, 50, 100];

export const addSpacesBetweenCapLocks = (inputString: string) => {
	if (!inputString) return '';
	// Use a regular expression to find capital letters followed by lowercase letters
	const result = inputString?.replace(/([a-z])([A-Z])/g, '$1 $2');
	return result;
};

export const clipText = (text: string, maxLength: number) => {
	if (!text) return '';
	const diff = text?.length - maxLength;
	const regexExpression = `(.|\n){${diff}}$`;
	const regex = new RegExp(regexExpression, 'g');
	return text.replace(regex, '...');
};

export const capitalizeFirstLetter = (string: string) => {
	return string
		? string?.replace(
				/(^\w|\s\w)(\S*)/g,
				(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
			)
		: '';
};

export const getPercentage = ({ numerator, denominator }: any) => {
	const percent = (numerator * 100) / denominator;

	// Cap the percentage at 100% if it exceeds
	return percent > 100 ? 100 : percent;
};

export const pathName = () => typeof window !== 'undefined' && window;

export const _isAnEmptyObject = (obj: any) => {
	for (const key in obj) {
		//console.log('key', key);

		if (obj?.prototype?.hasOwnProperty?.call(key)) return false;
		else return false;
	}
	return true;
};

export const _getToken = () => {
	const token = localStorage.getItem('game-user-token')!;

	return token;
};

export const _getMerchantDomain = () => {
	const merchantDomain = localStorage.getItem('merchant-domain')!;

	return merchantDomain;
};

export const _getHunterConnectionKey = () => {
	const hunterConnectionKey = localStorage.getItem('hunter-key')!;

	return hunterConnectionKey;
};

export const _getSpinConnectionKey = () => {
	const spinConnectionKey = localStorage.getItem('spin-key')!;

	return spinConnectionKey;
};

export const _getIceTimerMinutes = () => {
	const iceTimerMinutes = localStorage.getItem('ice-timer')!;

	return iceTimerMinutes;
};

export const _getSecret = () => {
	const secret = localStorage.getItem('game-user-secret')!;

	return secret;
};

export const _getUser = () => {
	const stringifiedUser = localStorage.getItem('game-user-obj');
	// console.log('stringified user=0= ', JSON?.parse(stringifiedUser));
	if (stringifiedUser && stringifiedUser !== 'undefined') {
		const localUser: any = JSON.parse(stringifiedUser);
		// const user = _isAnEmptyObject(localUser) ? null : localUser;
		return localUser;
	}
	return null;
};

export const _isUserLoggedIn = (): boolean => {
	const user = _getUser();
	if (!_isAnEmptyObject(user) && _getToken()) {
		return true;
	}
	return false;
};

// Extract the first 8 characters of the original string and append "..." to the end
export const shortenString = (originalString: string, length = 8) => {
	if (!originalString) return null;
	const shortened = `${originalString?.substring(0, length)}...`;
	return shortened;
};

export const convertToISODate = (dateString: any) => {
	if (!dateString) return '';

	const parts = dateString?.split('-');
	const isoDate = new Date(
		Date.UTC(parts[0], parts[1] - 1, parts[2])
	).toISOString();
	return isoDate;
};

export const convertTimestamp = (timestamp: any) => {
	if (!timestamp) return '';

	const now: any = new Date();
	const targetDate: any = new Date(timestamp);

	const diffInMilliseconds = Math.abs(targetDate - now);

	const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor(
		(diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
	);

	if (days === 0 && hours === 0) {
		return `${minutes}m`;
	} else if (days === 0) {
		return `${hours}hrs ${minutes}m`;
	}

	return `${days}days ${hours}hrs ${minutes}m`;
};

export const formatTime = (date: string) => {
	if (!date) return '-';

	const inputDate = new Date(date);
	const utcString = inputDate.toISOString();
	const utcDate = new Date(utcString);

	const day = String(utcDate.getUTCDate()).padStart(2, '0');
	const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
	const year = String(utcDate.getUTCFullYear()).slice(2);
	const hours = String(utcDate.getUTCHours()).padStart(2, '0');
	const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');

	const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

	return formattedDate;
};

export const formatChatTime = (date: string) => {
	if (!date) return '-';

	const inputDate = new Date(date);
	const utcString = inputDate.toISOString();
	const utcDate = new Date(utcString);

	const day = String(utcDate.getUTCDate()).padStart(2, '0');
	const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
	const year = String(utcDate.getUTCFullYear()).slice(2);
	const hours = String(utcDate.getUTCHours()).padStart(2, '0');
	const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');

	const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
	const formattedTime = moment(formattedDate, 'DD-MM-YY HH:mm').format(
		'hh:mm A | MMM. DD'
	);

	return formattedTime;
};

export const formatShortTime = (date: string) => {
	if (!date) return '-';

	const inputDate = new Date(date);
	const utcString = inputDate.toISOString();
	const utcDate = new Date(utcString);

	const day = String(utcDate.getUTCDate()).padStart(2, '0');
	const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
	const year = String(utcDate.getUTCFullYear()).slice(2);
	const hours = String(utcDate.getUTCHours()).padStart(2, '0');
	const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');

	const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
	const formattedTime = moment(formattedDate, 'DD-MM-YY HH:mm').format(
		'DD MMM. hh:mm a'
	);

	return formattedTime;
};

export const formatMoney = (amount: any, isDecimal = true) => {
	if (!amount) return null;

	const formatter = isDecimal
		? new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})
		: new Intl.NumberFormat('en-US', {});

	return formatter.format(amount);
};

type IgameCurrencyToFiatConversionProps = {
	gameCurrencyValue?: number;
	gameCurrencyOneUsdValue?: number;
	localCurrencyOneUsdValue?: number;
};

export const gameCurrencyToFiatConversion = ({
	gameCurrencyValue,
	gameCurrencyOneUsdValue,
	localCurrencyOneUsdValue,
}: IgameCurrencyToFiatConversionProps) => {
	const gameCurrency2UsdVal = gameCurrencyValue! / gameCurrencyOneUsdValue!;
	const fiatEquivalent = gameCurrency2UsdVal! * localCurrencyOneUsdValue!;
	return fiatEquivalent;
};

export const fiatToGameCurrency = ({
	fiatValue,
	localCurrencyOneUsdValue,
	gameCurrencyOneUsdValue,
}: any) => {
	const usdAmount = fiatValue / localCurrencyOneUsdValue;
	const cowryAmount = usdAmount * gameCurrencyOneUsdValue;
	return cowryAmount;
};

export const usdtToGameCurrency = ({
	usdAmount,
	gameCurrencyOneUsdValue,
}: any) => {
	const cowryAmount = usdAmount * gameCurrencyOneUsdValue;
	return cowryAmount;
};

export const gameCurrencyToUsdt = ({
	gameCurrencyValue,
	gameCurrencyOneUsdValue,
}: any) => {
	const usdAmount = gameCurrencyValue / gameCurrencyOneUsdValue;
	return usdAmount;
};

export const isFloat = (value: number) => {
	if (
		typeof value === 'number' &&
		!Number.isNaN(value) &&
		!Number.isInteger(value)
	) {
		return true;
	}

	return false;
};

export const storeReferralUsername = (username: string) =>
	localStorage.setItem('refName', username);

export const retrieveReferralUsername = () => localStorage.getItem('refName');

export const deleteReferralUsername = () => localStorage.removeItem('refName');

export const validateBitcoinAddress = (walletAddress: string) => {
	if (!walletAddress) return null;

	// wallet address regex pattern
	const regexPattern = /^0x[a-fA-F0-9]{40}$/;

	return regexPattern.test(walletAddress);
};

export const validateTonAddress = (walletAddress: string) => {
	if (!walletAddress) return null;
	const tonAddressPattern = /^(EQ|UQ)[0-9A-Za-z_-]{39,}$/;
	return tonAddressPattern.test(walletAddress);
};

export const validateBep20Address = (walletAddress: string) => {
	if (!walletAddress) return null;
	const bep20AddressPattern = /^0x[a-fA-F0-9]{40}$/;
	return bep20AddressPattern.test(walletAddress);
};

export const validateErc20Address = (walletAddress: string) => {
	if (!walletAddress) return null;
	const erc20AddressPattern = /^0x[a-fA-F0-9]{40}$/;
	return erc20AddressPattern.test(walletAddress);
};

export const getTodayDate = () => {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');

	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

export const getPositionText = (position: any) => {
	if (position === 0) {
		return `${position}`;
	}

	if (position % 100 >= 11 && position % 100 <= 13) {
		return `${position}th`;
	}

	const lastDigit = position % 10; // Get the last digit of the position

	if (lastDigit === 1) {
		return `${position}st`;
	} else if (lastDigit === 2) {
		return `${position}nd`;
	} else if (lastDigit === 3) {
		return `${position}rd`;
	} else if (position > 0) {
		return `${position}th`;
	}
};

export const timeAgo = (timestamp: any) => {
	if (!timestamp) return '';

	const currentDate: any = new Date();
	const previousDate: any = new Date(timestamp);

	const timeDifferenceInSeconds = Math.floor(
		(currentDate - previousDate) / 1000
	);

	if (timeDifferenceInSeconds < 60) {
		return `${timeDifferenceInSeconds} ${
			timeDifferenceInSeconds < 2 ? 'sec' : 'secs'
		}  ago`;
	} else if (timeDifferenceInSeconds < 3600) {
		const minutes = Math.floor(timeDifferenceInSeconds / 60);
		return `${minutes} ${minutes < 2 ? 'min' : 'mins'}  ago`;
	} else if (timeDifferenceInSeconds < 86400) {
		const hours = Math.floor(timeDifferenceInSeconds / 3600);
		return `${hours} ${hours < 2 ? 'hr' : 'hrs'} ago`;
	} else {
		const days = Math.floor(timeDifferenceInSeconds / 86400);
		return `${days} ${days < 2 ? 'day' : 'days'} ago`;
	}
};

export const createImage = (url: any) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

export function getRadianAngle(degreeValue: any) {
	return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: any, height: any, rotation: any) {
	const rotRad = getRadianAngle(rotation);

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
	};
}

export async function getCroppedImg(
	imageSrc: any,
	pixelCrop: any,
	rotation = 0,
	flip = { horizontal: false, vertical: false }
) {
	const image: any = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	if (!ctx) {
		return null;
	}

	const rotRad = getRadianAngle(rotation);

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	const croppedCanvas = document.createElement('canvas');

	const croppedCtx = croppedCanvas.getContext('2d');

	if (!croppedCtx) {
		return null;
	}

	// Set the size of the cropped canvas
	croppedCanvas.width = pixelCrop.width;
	croppedCanvas.height = pixelCrop.height;

	// Draw the cropped image onto the new canvas
	croppedCtx.drawImage(
		canvas,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	// As Base64 string
	// return croppedCanvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve) => {
		croppedCanvas.toBlob((file: any) => {
			resolve(URL.createObjectURL(file));
		}, 'image/jpeg');
	});
}

export const getFormattedDate = (date: any) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

export const getCurrentDate = () => {
	const today = new Date();
	return getFormattedDate(today);
};

export const getLast7Days = () => {
	const today = new Date();
	const last7Days = new Date(today);
	last7Days.setDate(today.getDate() - 7);

	return getFormattedDate(last7Days);
};

export const getLastMonth = () => {
	const today = new Date();
	const lastMonth = new Date(today);
	lastMonth.setMonth(today.getMonth() - 1);

	return getFormattedDate(lastMonth);
};

export const getLast6Months = () => {
	const today = new Date();
	const last6Months = new Date(today);
	last6Months.setMonth(today.getMonth() - 6);

	return getFormattedDate(last6Months);
};

export const getLastYear = () => {
	const today = new Date();
	const lastYear = new Date(today);
	lastYear.setFullYear(today.getFullYear() - 1);

	return getFormattedDate(lastYear);
};

export const isIterable = (obj: any): obj is Iterable<any> => {
	try {
		return typeof obj[Symbol.iterator] === 'function';
	} catch (e) {
		return false;
	}
};

interface Reward {
	startRank: number;
	endRank: number;
	amount: number;
}

export const calculatePlayerPrizeByIndex = (
	playerIndex: number,
	rewards: Reward[]
): number => {
	const reward = rewards?.find(
		(r) => playerIndex >= r.startRank && playerIndex <= r.endRank
	);
	return reward ? reward.amount : 0;
};

export const formatDate = (date: any) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
};

export const getTodayAndYesterday = () => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	return {
		today: formatDate(today),
		yesterday: formatDate(yesterday),
	};
};

export const convertDateFormat = (inputDateString: string) => {
	if (!inputDateString) return '-';
	const inputDate = new Date(inputDateString);

	const day = inputDate.getDate();
	const monthIndex = inputDate.getMonth();
	const year = inputDate.getFullYear();

	const monthNames = [
		'Jan',
		'Feb',
		'March',
		'April',
		'May',
		'June',
		'July',
		'Aug',
		'Sept',
		'Oct',
		'Nov',
		'Dec',
	];

	const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;
	return formattedDate;
};

export const formatString = (inputString: string) => {
	if (!inputString) return null;
	const words = inputString.split('_');

	return words.join('');
};

export const convertUTCToLocal = (utcDateTimeString: string) => {
	// first get user timeZone
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	// console.log('tie zone>>> ', timeZone);

	// Parse the UTC datetime string into a Date object
	const utcDate = new Date(utcDateTimeString);

	// Get the user's local date and time
	const localDate = new Date(
		utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
	);

	// Format the local date and time as a string (optional)
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZone: timeZone,
		// hour12: true,
	};

	// Use toLocaleString to format the date according to the user's locale
	return localDate.toLocaleString(undefined, options);
};

export const formatUtcLocalTime = (date: string) => {
	if (!date) return '-';

	// Parse the input date string into a Date object
	const inputDate = new Date(date);

	// Get the user's local timezone
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// Convert the UTC date to the user's local date and time using the specified timezone
	const options: Intl.DateTimeFormatOptions = {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZone: userTimeZone,
		hour12: false,
	};

	// Use toLocaleString to format the date according to the specified timezone
	const localDateTimeString = new Intl.DateTimeFormat('en-GB', options).format(
		inputDate
	);

	// Extract parts from the formatted string
	const parts = localDateTimeString.match(
		/(\d{2})\/(\d{2})\/(\d{2}), (\d{2}):(\d{2}):(\d{2})/
	);
	if (!parts) {
		throw new Error('Failed to parse local datetime string');
	}
	const [, day, month, year, hours, minutes] = parts;

	// Format the final date string
	const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

	return formattedDate;
};

export const currencyFormatter = (num: number) => {
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1) + 'm';
	} else if (num >= 1000) {
		return (num / 1000).toFixed(1) + 'k';
	} else {
		return num.toFixed(1).toString();
	}
};
