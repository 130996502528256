import { odogwuThumbnail, raffleThumbnail, spinThumbnail } from 'assets';
import { Button } from 'components/button/Button';
import DemoGameModal from 'components/modals/demoGameModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { _getToken, formatMoney } from 'utils';
import usericon from 'assets/icons/user.svg';
import SoundPlayer from 'components/sounds/GameSounds';

export const GameListRow = ({
	activePlayers,
	btnText,
	route,
	isGameEnabled,
	thumbnail,
	name,
	type,
	className,
}: any) => {
	const navigate = useNavigate();

	const [openDemoModal, setOpenDemoModal] = useState(false);

	const closeDemoModal = () => {
		setOpenDemoModal(false);
	};

	const token = _getToken();

	const navigateToSelectedGame = (route: string) => {
		SoundPlayer.onGameStart();
		SoundPlayer.playSound('gameStart');
		setTimeout(() => navigate(`/${route}`), 500);
	};

	return (
		<>
			<div
				style={{
					background: `url(${
						thumbnail === 'hunter'
							? odogwuThumbnail
							: thumbnail === 'spin'
								? spinThumbnail
								: thumbnail === 'raffle'
									? raffleThumbnail
									: thumbnail
					})`,

					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'top center',
					backgroundSize: 'cover',
				}}
				className={`relative p-3 rounded-[1rem] lg:h-[12rem] ${className}`}
			>
				<div className="lg:flex hidden items-center gap-2 bg-white text-[#426306] rounded-xl px-2 py-1 text-[13px] font-medium w-fit mr-3 ml-auto">
					<img src={usericon} /> {formatMoney(activePlayers, false) || 0}
				</div>

				<Button
					text={isGameEnabled ? btnText : 'Loading...'}
					onClick={() => {
						if (token) {
							isGameEnabled && type === 'demo'
								? setOpenDemoModal(true)
								: isGameEnabled && type !== 'demo'
									? navigateToSelectedGame(route)
									: '';
						} else {
							// setOpen(true);
							navigate('/auth/get-started');
						}
					}}
					data-testid={route}
					className={` ${
						isGameEnabled
							? 'btn-secondary-gradient cursor-pointer text-[#0F121D]'
							: 'bg-[#686A72] text-other cursor-default'
					} rounded-[40px] w-fit mx-auto flex text-[1rem] font-[700] px-4 py-2 whitespace-nowrap absolute bottom-4 left-0 right-0`}
				/>
			</div>

			{openDemoModal && (
				<DemoGameModal
					closeModal={closeDemoModal}
					route={route}
					data={name}
					thumbnail={thumbnail}
				/>
			)}
		</>
	);
};
