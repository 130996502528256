import { useNavigate } from 'react-router-dom';
import { Button3d } from 'components/button/Button';
import close_btn from 'assets/icons/bot-modal-cancel.svg';
import Background from 'assets/images/special-offer2.png';
import Spin from 'assets/gameAssets/spin.png';
import Shield from 'assets/gameAssets/shield.png';
import Odumeje from 'assets/gameAssets/odumeje.png';
import Shaggy from 'assets/gameAssets/shaggy.png';
import Ice from 'assets/gameAssets/ice.png';
import Tournament from 'assets/gameAssets/tournament.png';
import { useTranslation } from 'react-i18next';

export interface Props {
	modalClose?: () => void;
}

export const SpecialOfferModal = ({ modalClose }: Props) => {
	const navigate = useNavigate();
	const { t } = useTranslation('common');

	return (
		<div className="h-full w-full">
			<main className="relative h-[100%] w-full flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="w-full relative h-full ">
					<div className="text-primary text-left w-full h-[33%] object-cover">
						<img
							src={Background}
							alt="background"
							className="rounded-tl-[28px] rounded-tr-[28px] w-full h-full"
						/>
						<div className="bg-[#363636] rounded-full absolute right-3 top-5">
							<img
								src={close_btn}
								onClick={() => modalClose && modalClose()}
								alt="close"
								className="h-[3rem]"
							/>
						</div>
					</div>

					<div className="w-full h-full max-h-[77%] px-4 md:px-6 py-10 text-white rounded-tl-[22px] rounded-tr-[22px] special-offer-bg overflow-y-scroll relative -top-[10%] z-10">
						<p
							style={{ textShadow: '0px 4px black' }}
							className="luckiest-guy-regular font-bold text-[1.8rem] text-center leading-tight mb-4"
						>
							Get Access to Hammer Games Premium
						</p>

						<ul className="flex flex-col gap-3 w-full bg-[#00000052] px-5 py-5 mt-10 rounded-[12px]">
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img className="h-[30px] w-[30px]" src={Spin} alt="asset" />
								<span className="leading-tight">
									5 Daily Free Spins to get bonuses
								</span>
							</li>
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img
									className="h-[30px] w-[30px]"
									src={Tournament}
									alt="asset"
								/>
								<span className="leading-tight">
									Claim Daily Leaderboard rewards
								</span>
							</li>
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img className="h-[30px] w-[30px]" src={Ice} alt="asset" />
								<span className="leading-tight">400 Ice For 30 days</span>
							</li>
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img className="h-[30px] w-[30px]" src={Shield} alt="asset" />
								<span className="leading-tight">
									400 GameShield For 30 days
								</span>
							</li>
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img className="h-[30px] w-[30px]" src={Shaggy} alt="asset" />
								<span className="leading-tight">
									400 Shaggi Hunter For 30 days
								</span>
							</li>
							<li className="text-[1rem] font-bold flex items-center gap-3">
								<img className="h-[30px] w-[30px]" src={Odumeje} alt="asset" />
								<span className="leading-tight">
									400 Indaboski Hunter For 30 days
								</span>
							</li>
						</ul>

						{/* <p className="text-[1.1rem] font-bold my-4 text-center">Buy For</p> */}

						<Button3d
							text={t('onboard.subscribeNow')}
							onClick={() => {
								navigate('/subscriptions');
							}}
							disabled={false}
							className={`font-semibold w-full flex items-center justify-center h-[56px] mt-20 my-4 mb-6`}
						/>
					</div>
				</div>
			</main>
		</div>
	);
};
